import React from 'react';
import Loading from '../components/Loading';
import store from '../redux/store';

export default function LazyComponent (importComponent: () => Promise<any>) {
  class LazyComponent extends React.Component<any> {
    state: any
    constructor (props: any) {
      super(props);
 
      this.state = {
        component: null
      };
    }
 
    componentDidMount() {
      // *组件开始加载
      importComponent()
        .then(cmp => {
          this.setState({ component: cmp.default });
        });
    }

    componentDidUpdate() {
      // *判断当前组件是否被记录了滚动条高度
      const { route, afterEach } = this.props;
      if (route.isKeep) {
        window.scrollTo(0, store.getState().route.scrollHistory[route.path] || 0);
      }
      // *组件加载完成
      if (afterEach) {
        afterEach()
      }
    }

    render() {
      const { component: C } = this.state;
 
      return C ? <C {...this.props} /> : <Loading />;
    }
  }
 
  return LazyComponent;
}