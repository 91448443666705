import { connect } from 'react-redux';
import Header from '../components/Header';
import router from '../routes';
import { RouteType } from '../routes/router';

const mapState = (state: any) => {
  return {
    currentRouteInfo: state.route.route
  }
}

interface ChildLayoutPropsType {
  route: RouteType;
  currentRouteInfo: any
}

function ChildLayout({ route: { routes }, currentRouteInfo }: ChildLayoutPropsType) {
  return (
    <>
      <Header
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100vw',
          transform: 'translateZ(0px)',
        }}
      >
        {currentRouteInfo.title}
      </Header>
      <div
        style={{
          marginTop: '40px',
          maxHeight: 'calc(100vh - 40px)',
          overflowY: 'auto',
        }}
      >
        {router.renderRoute(routes as RouteType[])}
      </div>
    </>
  );
}

export default connect(
  mapState
)(ChildLayout);
