import actionTypes from '../actions'

const defaultArticleValue = {
  content: [],
  size: 25,
  page: 0,
  total: 0,
  last: true,
  first: true,
};
export interface HomeStateType {
  articleCategoryList: API.ArticleCategoryPropType[];
  articleCategoryLoading: true;
  articleList: any;
  tabsIndexValue: number;
  tabsScroll: any,
}

const dynamicState: HomeStateType = {
  articleCategoryList: [],
  articleCategoryLoading: true,
  articleList: {
    '-1': {
      ...defaultArticleValue,
      categoryId: -1,
    },
  },
  tabsIndexValue: 0,
  tabsScroll: {},
};

const reducer = (state = dynamicState, action: any) => {
  const { type, payload } = action;
  switch (type) {
    case actionTypes.HOME_CHANGE:
      const articleList = state.articleList;
      payload?.articleCategoryList?.forEach((item: any) => {
        articleList[item.id] = articleList[item.id] || {
          ...defaultArticleValue,
          categoryId: item.id,
        };
      });
      return {
        ...state,
        ...payload,
        articleList,
      }
    case actionTypes.HOME_ARTICLE_LIST:
      return {
        ...state,
        articleList: {
          ...state.articleList,
          ...payload,
        }
      }
    case actionTypes.HOME_ARTICLE_LIST_CHANGE:
      return {
        ...state,
        articleList: {
          ...state.articleList,
          [payload.categoryId]: {
            ...state.articleList[payload.categoryId],
            content: state.articleList[payload.categoryId].content.map((item: any) => {
              if (item.id === payload.id) {
                return {
                  ...item,
                  $isRead: true,
                }
              }
              return item;
            })
          }
        }
      }
    case actionTypes.HOME_TABS_INDEX_AND_SCROLL:
      return {
        ...state,
        tabsIndexValue: payload.tabsIndexValue,
        tabsScroll: {
          ...state.tabsScroll,
          ...payload.tabsScroll,
        }
      }
    default:
      return state
  }
}

export default reducer;