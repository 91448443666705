import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import App from './views/App';
import store from './redux/store';
import { setUserInfo } from './redux/actions/user';
import { getSearch } from './utils/location';

const { oauthUrl, status: storeStatus } = store.getState().user;
const toGoLogin = getSearch('code');

if (storeStatus !== 'AUTH_SUCCESS' && !toGoLogin) {
  // *用户没有授权 并且url中没有wxCode 就跳转到授权页面
  window.location.replace(oauthUrl);
} else {
  // *url中不管有无wxCode 都要触发login去获取用户信息状态
  store.dispatch(setUserInfo(toGoLogin, () => {
    setTimeout(() => {
      ReactDOM.render(
        <React.StrictMode>
          <Provider store={store}>
            <App />
          </Provider>
        </React.StrictMode>,
        document.getElementById('UNQ')
      );
    }, 1000);
  }));
}

// store.subscribe(() => {
//   // *订阅uesr仓库
//   console.log(`---  订阅[ user ]status: ${store.getState().user.status} ---`);
// })