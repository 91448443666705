import axios from '../axios';

export const jsapiSignature = (url: string): Promise<any> => {
  return axios.request({
    method: 'get',
    url: '/api/wechat/mp/jsapiSignature',
    params: {
      url,
    }
  })
}

