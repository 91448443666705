import actionTypes from '../actions'

const { REACT_APP_REFERER, REACT_APP_APPID } = process.env;

const redirectUri = `${REACT_APP_REFERER}/${window.location.hash || '#/'}`;

const userInfo = localStorage.getItem('userInfo') && JSON.parse(localStorage.getItem('userInfo') as string);

export interface UserStateType {
  status: 'AUTH_NO' | 'AUTH_ING' | 'AUTH_SUCCESS' | 'AUTH_ERROR';
  userInfo: any;
  redirectUri: string;
  oauthUrl: string;
  wxConfig: any;
}

const userState: UserStateType = {
  status: userInfo ? 'AUTH_SUCCESS' : 'AUTH_NO',
  userInfo: userInfo || {},
  redirectUri,
  oauthUrl: `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${REACT_APP_APPID}&redirect_uri=${encodeURIComponent(redirectUri)}&response_type=code&scope=snsapi_userinfo#wechat_redirect`,
  wxConfig: {},
};

const reducer = (state = userState, action: any) => {
  const { type, payload } = action;
  switch (type) {
    case actionTypes.SET_USER_INFO:
      return {
        ...state,
        ...payload,
      }
    case actionTypes.SET_USER_STATUS:
      return {
        ...state,
        status: payload
      }
    case actionTypes.CLEAR_USER_INFO:
      return {
        ...state,
        ...payload,
      }
    case actionTypes.SET_WX_CONFIG:
      return {
        ...state,
        wxConfig: payload,
      }
    default:
      return state
  }
}

export default reducer;