import axios from '../axios';

export const articleCategory = (position: 'HOME' | 'SQUARE'): Promise<any> => {
  return axios.request({
    method: 'get',
    url: '/api/article/category',
    params: {
      position,
    },
  });
}

export const getBanner = (position: '首页' | '广场'): Promise<any> => {
  return axios.request({
    method: 'get',
    url: '/api/banner',
    params: {
      position,
    },
  })
}
