import { Modal } from 'zarm';
import actionTypes from './index';
import { DispatchPropType, ReducerPropsType } from '../store.d';
import { accountLogin } from '../../request/modules/account';
import { jsapiSignature } from '../../request/modules/wxJSSDK';
import blackUserCheck from '../../utils/blackUserCheck';

/**
 * 获取用户信息
 *
 * @param {(string | undefined)} code wxCode码
 * @param {() => void} cb 接口执行完毕的回调
 */
export const setUserInfo = (code: string | undefined, cb?: () => void) => (dispatch: DispatchPropType, getState: any) => {
  dispatch({ type: actionTypes.SET_USER_STATUS, payload: 'AUTH_ING' });

  accountLogin(code)
    .then(({ code, data }) => {
      if (code === 0) {
        localStorage.setItem('userInfo', JSON.stringify(data));
        dispatch(({
          type: actionTypes.SET_USER_INFO,
          payload: {
            userInfo: data,
            status: 'AUTH_SUCCESS'
          }
        }));
        // *login成功之后 所需要去做的事情
        cb && cb();
        import('../../utils/wxJSSDK');
      } else {
        localStorage.removeItem('userInfo');
        window.location.replace(getState().user.oauthUrl);
        // dispatch(({
        //   type: actionTypes.SET_USER_INFO,
        //   payload: {
        //     userInfo: {},
        //     status: 'AUTH_ERROR'
        //   }
        // }))
      }
    })
    .catch((e) => {
      localStorage.removeItem('userInfo');
      if (blackUserCheck(e.response)) {
        Modal.alert({
          content: '该账号无法登录，请联系管理员',
          onCancel: () => {
            wx.closeWindow();
          },
        });
        return;
      }
      window.location.replace(getState().user.oauthUrl);
      // dispatch(({
      //   type: actionTypes.SET_USER_INFO,
      //   payload: {
      //     userInfo: {},
      //     status: 'AUTH_ERROR'
      //   }
      // }))
    })
};

export const clearUserInfo = (): ReducerPropsType => ({
  type: actionTypes.CLEAR_USER_INFO,
  payload: {
    status: 'AUTH_NO',
    userInfo: {},
  },
});

export const setWxConfig = (url: string, cb: (data: any) => void) => (dispatch: DispatchPropType) => {
  jsapiSignature(url)
    .then(({ code, data }) => {
      if (code === 0) {
        dispatch({
          type: actionTypes.SET_WX_CONFIG,
          payload: data,
        });
        cb(data);
      }
    })
};