/*
 * @Author: your name
 * @Date: 2021-08-15 20:07:28
 * @LastEditTime: 2021-08-22 11:16:29
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \新建文件夹\src\routes\index.tsx
 */
import lazy from '../HOC/LazyComponent';
import Router, { RouteType } from './router';
import { Base, Child } from '../layouts';

const routes: RouteType[] = [
  {
    title: '登录页',
    path: '/login',
    exact: true,
    component: lazy(() => import('../views/login')),
  },
  {
    title: '搜索页',
    path: '/search',
    exact: true,
    component: lazy(() => import('../views/search')),
  },
  {
    title: '建言建语详情',
    path: '/advice/:id',
    exact: true,
    component: lazy(() => import('../views/discussion/advice/detail')),
  },
  {
    path: '/main',
    component: Base.default,
    routes: [
      {
        title: '首页',
        icon: 'accountBalance',
        path: '/main/home',
        isKeep: true,
        component: lazy(() => import('../views/home')),
      },
      {
        title: '有趣广场',
        icon: 'people',
        path: '/main/discussion',
        isKeep: true,
        component: lazy(() => import('../layouts/').then((module) => ({ default: module.Square }))),
        routes: [
          {
            path: '/main/discussion/advice',
            exact: true,
            component: lazy(() => import('../views/discussion/advice')),
          },
          {
            path: '/main/discussion/:id',
            component: lazy(() => import('../views/discussion/square')),
          },
        ],
      },
      {
        title: '商社',
        icon: 'favorite',
        path: '/main/community',
        openLaunchWeapp: process.env.REACT_APP_WX_OPEN_LAUNCH_WEAPP,
      },
      {
        title: '个人中心',
        icon: 'accessibility',
        path: '/main/user',
        isKeep: true,
        // authority: ['admin'],
        component: lazy(() => import('../views/user')),
      },
    ]
  },
  {
    path: '/credit/detail',
    component: lazy(() => import('../views/user/credit')),
  },
  {
    path: '/child',
    component: Child.default,
    routes: [
      {
        title: '文章详情',
        exact: true,
        path: '/child/article/:id',
        component: lazy(() => import('../views/article')),
      },
      {
        title: '个人中心详情页',
        exact: true,
        path: '/child/user-details',
        component: lazy(() => import('../views/user/details')),
      },
      {
        title: '关于公司',
        exact: true,
        path: '/child/user-about',
        component: lazy(() => import('../views/user/about')),
      },
    ]
  },
];

const router = new Router(routes);

export default router;