import Nav from '../components/Nav';
import router from '../routes';
import { RouteType } from '../routes/router';

const navStyle: React.CSSProperties = {
  position: 'fixed',
  bottom: 0,
  left: 0,
  transform: 'translateZ(0)',
};

function BaseLayout({ route: { routes } }: { route: RouteType }) {
  return (
    <>
      <div
        style={{
          marginBottom: 'env(safe-area-inset-bottom)',
        }}
      >
        {router.renderRoute(routes as RouteType[])}
      </div>
      <Nav
        sourceData={routes as RouteType[]}
        style={navStyle}
      />
    </>
  );
}

export default BaseLayout;
