import axios from '../axios';

export const accountLogin = (wxCode?: string): Promise<any> => {
  return axios.request({
    method: 'get',
    url: '/api/account/login',
    params: {
      wxCode,
    }
  })
}

export const emailWhitelist = (): Promise<any> => {
  return axios.request({
    method: 'get',
    url: '/api/emailWhitelist',
  })
}

interface AccountSendMailPropsType {
  ticket: string;
  rand: string;
  mail: string;
}

export const accountSendMail = ({ ticket, rand, mail }: AccountSendMailPropsType): Promise<any> => {
  return axios.request({
    method: 'post',
    url: '/api/account/sendMail',
    params: {
      ticket,
      rand,
      mail,
    }
  })
}