import Axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import store from '../redux/store';
import { clearUserInfo } from '../redux/actions/user';
import blackUserCheck from '../utils/blackUserCheck';

// !https://www.kancloud.cn/yunye/axios/234845/

class Request {
  baseUrl: string;
  constructor(baseUrl?: string) {
    this.baseUrl = baseUrl || '';
  }

  // *配置项
  config() {
    return {
      timeout: 30000,
      withCredentials: true,
    }
  }

  // *拦截器
  interceptors(instance: AxiosInstance) {
    // 添加请求拦截器
    instance.interceptors.request.use((config) => {
      // 在发送请求之前做些什么
      return config;
    }, (error) => {
      // 对请求错误做些什么
      return Promise.reject(error);
    });

    // 添加响应拦截器
    instance.interceptors.response.use((response) => {
      // 对响应数据做点什么
      return response.data;
    }, (error) => {
      // 对响应错误做点什么
      const { response } = error;
      this.responseStatus(response);
      return Promise.reject(error);
    });
  }

  responseStatus(response: any) {
    switch (response.status) {
      case 401:
        if (blackUserCheck(response)) {
          break;
        }
        store.dispatch(clearUserInfo());
        window.location.href = '#/login';
        break;
      default:
        break;
    }
  }

  // *实例化
  request(options: AxiosRequestConfig) {
    var instance = Axios.create();
    options = Object.assign(this.config(), options)
    this.interceptors(instance);
    return instance(options);
  }
}

const axios = new Request(process.env.REACT_APP_BASEURL);

export default axios;