import React from 'react';
import { RouteProps } from 'react-router';
import { HashRouter, Prompt } from 'react-router-dom';
import { Redirect, Route, Switch } from 'react-router';
import lazy from '../HOC/LazyComponent';
import store from '../redux/store';
import { setScrollHistory } from '../redux/actions/route';

export interface RouteType extends RouteProps {
  title?: string;
  icon?: string;
  authority?: string[];
  routes?: RouteType[];
  redirect?: string;
  isKeep?: boolean;
  openLaunchWeapp?: string;
}

type beforeEachCallBackType = (to: string, next: any) => JSX.Element | React.ReactNode | void;
type afterEachCallBackType = (to: string, route: RouteType) => void;

/**
 * 路由
 * 
 * 默认注入: 1.首页重定向组件 2.NoAuth组件(无权限) 3.NoMatch组件(无页面)
 * @class Router
 */
class Router {
  routerList: RouteType[]
  current: RouteType
  beforeEachCallBack: beforeEachCallBackType
  afterEachCallBack: afterEachCallBackType

  constructor(routerList: RouteType[]) {
    this.routerList = [
      ...routerList,
      {
        path: '/',
        exact: true,
        redirect: '/main/home',
      },
      {
        path: '/noAuth',
        exact: true,
        component: lazy(() => import('../views/noAuth')),
      },
      {
        path: '*',
        component: lazy(() => import('../views/noMatch')),
      }
    ]
    this.current = {};
    this.beforeEachCallBack = () => { };
    this.afterEachCallBack = () => { };
  }

  /**
   * 渲染路由
   *
   * @param {RouteType[]} routesData
   * @return {*} 
   * @memberof Router
   */
  renderRoute(routesData: RouteType[]) {
    return routesData.map(route => {
      const { path, exact, redirect, authority, isKeep, component: Component } = route;

      const p = {
        key: (path as string),
        path,
        exact,
        render:
          (props: any) => {
            // *本次路由信息进行记录
            this.current = route;

            // *路由拦截
            const { location: { pathname } } = props;
            const isIntercept = this.beforeEachCallBack(pathname, this.next);
            if (isIntercept) {
              return isIntercept;
            }

            // *路由鉴权
            if (
              authority
              && authority.length
              && !authority.includes(store.getState().user.userInfo.role)
            ) {
              return this.next('/noAuth');
            }

            // *判断是否重定向
            if (redirect) {
              return this.next(redirect);
            }

            // *一切正常 开始渲染组件
            if (Component) {
              return (
                <>
                  {isKeep ? (
                    <Prompt
                      when
                      message={() => {
                        store.dispatch(
                          setScrollHistory(
                            pathname,
                            document.documentElement.scrollTop || document.body.scrollTop || 0
                          )
                        );
                        return true
                      }}
                    />
                  ) : null}
                  <Component
                    {...{
                      ...props,
                      route,
                      afterEach: () => this.afterEachCallBack(pathname, route)
                    }}
                  />
                </>
              );
            }

            return null;
          }
      }

      return <Route {...p} />
    })
  }

  /**
   * 路由重定向
   *
   * @param {string} to
   * @return {*} 
   * @memberof Router
   */
  next(to: string, push: boolean = false) {
    return <Redirect to={to} exact push={push} />;
  }

  /**
   * 全局前置守卫-组件未加载
   * 拦截路由需调用next方法来 重定向地址
   * @param {beforeEachCallBackType} cb
   * @memberof Router
   */
  beforeEach(cb: beforeEachCallBackType) {
    this.beforeEachCallBack = cb;
  }

  /**
   * 全局后置钩子-组件挂载完成
   * 路由完成之后的一些逻辑处理
   * @param {beforeEachCallBackType} cb
   * @memberof Router
   */
  afterEach(cb: afterEachCallBackType) {
    this.afterEachCallBack = cb;
  }

  /**
   * 初始化路由
   *
   * @return {*} 
   * @memberof Router
   */
  init() {
    return (
      <HashRouter>
        <Switch>
          {this.renderRoute(this.routerList)}
        </Switch>
      </HashRouter>
    )
  }
}

export default Router;