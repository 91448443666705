import { connect } from 'react-redux';
import router from '../routes';
import dayjs from 'dayjs';
import zhCn from 'dayjs/locale/zh-cn';
import { ConfigProvider } from 'zarm';
import 'zarm/dist/zarm.min.css';
import './global.css';
import Watermark from '../components/Watermark';

import { setRoute as setRouteAction } from '../redux/actions/route';

dayjs.locale(zhCn);

const mapStateToProps = (state: any) => {
  return {
    userInfo: state.user.userInfo
  }
};

function App({ userInfo, setRouteAction }: any) {
  const RouterView = router.init();

  router.beforeEach((to, next) => {
    if (userInfo?.status !== '正常' && to !== '/login') {
      // ?用户状态非正常状态 只能前往登录页面
      return next('/login');
    }
    if (userInfo?.status === '正常' && to === '/login') {
      // ?用户状态正常状态 前往登录页面会重定向到首页
      return next('/');
    }
  })

  router.afterEach((to, route) => {
    wx.ready(function () {   //需在用户可能点击分享按钮前就先调用
      wx.updateAppMessageShareData({ 
        title: '员工之家', // 分享标题
        desc: '', // 分享描述
        link: 'http://community.rough-9999.com',
        imgUrl: '',
      })
    });
  })

  return (
    <ConfigProvider primaryColor="#108ee9">
      <>
        <Watermark mark={userInfo.mail} />
        {RouterView}
      </>
    </ConfigProvider>
  )
}

export default connect(
  mapStateToProps,
  { setRouteAction }
)(App);