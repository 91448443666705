export const getSearch = (name: string) => {
  let { search } = window.location;
  search = search.slice(1);
  const searchList = search.split('&').reduce((acc: { [key: string]: string }, item: string) => {
    const i = item.split('=');
    acc[i[0]] = i[1];
    return acc;
  }, {})

  return searchList[name];
}