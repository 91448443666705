import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { Icon } from 'zarm';
import styles from './index.module.css';

interface ChildLayoutPropsType {
  style?: React.CSSProperties;
  children?: React.ReactNode;
  right?: React.ReactNode;
}

function ChildLayout({ right, children, style}: ChildLayoutPropsType) {
  const history = useHistory();

  const handleChange = useCallback(() => {
    history.goBack();
  }, [history]);

  return (
    <nav className={styles['child-nav']} style={style}>
      <div className={styles.left} onClick={handleChange}>
        <Icon type="arrow-left" size="sm" style={{ marginRight: '4px' }} />
        返回
      </div>
      <div className={styles.content}>
        {children}
      </div>
      <div className={styles.right}>
        {right}
      </div>
    </nav>
  );
}

export default ChildLayout;
