import React from 'react';
import styles from './index.module.css';

const Watermark: React.FC<{
  mark?: string
}> = ({
  mark = '未登录'
}) => {

  return <div className={styles.waterMark}>
    {Array(12).fill('').map((v, i) => <span className={styles.mark} key={i}>{mark}</span>)}
  </div>;
}

export default Watermark;