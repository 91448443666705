import React from 'react';
import { ActivityIndicator } from 'zarm';
import styles from './index.module.css';

export default function LoadingComponent() {

  return (
    <div className={styles.content}>
      <ActivityIndicator size="lg" />
      <p>正在努力加载中...</p>
    </div>
  )
}
