/*
 * @Author: your name
 * @Date: 2021-08-21 17:48:02
 * @LastEditTime: 2021-08-22 21:59:18
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \新建文件夹\src\redux\reducers\article.ts
 */
import actionTypes from '../actions'

export interface ArticleStateType {
  sourceData: any;
  loading: boolean,
}

const articleState: ArticleStateType = {
  sourceData: {},
  loading: false,
};

const reducer = (state = articleState, action: any) => {
  const { type, payload } = action;
  // debugger;
  switch (type) {
    case actionTypes.SET_ARTICLE:
      return {
        ...state,
        ...payload
      }
    case actionTypes.SET_ARTICLE_LOADING:
      return {
        ...state,
        loading: payload
      }
    default:
      return state
  }
}

export default reducer;