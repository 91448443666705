import actionTypes from './index';
import { DispatchPropType } from '../store.d';
import { articleCategory } from '../../request/modules/home';
import { getArticle } from '../../request/modules/article';

/**
 * 设置文章阅读状态
 *
 */
export const setHomeArticleListChange = (
  categoryId: number,
  id: number,
) => ({
  type: actionTypes.HOME_ARTICLE_LIST_CHANGE,
  payload: {
    categoryId,
    id,
  }
});

/**
 * 获取文章列表
 *
 */
export const getArticleList = ({ page, size, id }: API.GetArticlePropType) =>
  (dispatch: DispatchPropType, getState: any) => {
    const articleList = getState().home.articleList;
    const currentListInfo = articleList[id];
    const params = {
      page,
      size,
      id,
    }

    if (typeof page !== 'number') {
      params.page = currentListInfo.page + 1;
      params.size = currentListInfo.size;
    }

    return new Promise((resolve, reject) => {
      getArticle(params)
        .then(({ code, data, msg }) => {
          if (code === 0) {
            if (data?.content?.length) {
              const { content, size, number, totalElements, last, first } = data;
              // *处理本次数据请求是否需要拼接
              const storageReadIds = JSON.parse(localStorage.getItem('readIds') || '[]');
              let sourceData: any[] = [];
              if (first) { // *请求第一页数据无需拼接
                sourceData = content;
              } else {
                sourceData = currentListInfo.content.concat(content);
              }
              dispatch({
                type: actionTypes.HOME_ARTICLE_LIST,
                payload: {
                  [id || -1]: {
                    content: sourceData.map((item) => ({
                      ...item,
                      $isRead: storageReadIds.indexOf(item.id) >= 0,
                    })),
                    size,
                    page: number,
                    total: totalElements,
                    last,
                    first,
                    categoryId: id,
                  }
                }
              });
            }
            resolve(data);
          } else {
            reject(msg);
          }
        })
        .catch((err) => {
          reject(err);
        })
    })
};

/**
 * 首页分类信息
 *
 */
export const setArticleCategory = (position: 'HOME' | 'SQUARE') => (dispatch: DispatchPropType, getState: () => any ) => {
  dispatch({ type: actionTypes.HOME_CHANGE, payload: { articleCategoryLoading: true } });
  return new Promise((resolve, reject) => {
    articleCategory(position)
    .then(({ code, data, msg }) => {
      if (code === 0) {
        let articleCategoryList = getState()?.home?.articleCategoryList;
        if (data?.length) {
          const others = articleCategoryList?.filter((item: any) => item?.position !== position) || [];
          articleCategoryList = [
            ...data,
            ...others,
          ];
        }
        dispatch({
          type: actionTypes.HOME_CHANGE,
          payload: {
            articleCategoryList,
            articleCategoryLoading: false,
          }
        });
        resolve(data);
      } else {
        dispatch({ type: actionTypes.HOME_CHANGE, payload: { articleCategoryLoading: false } });
        reject(msg);
      }
    })
    .catch((err) => {
      dispatch({ type: actionTypes.HOME_CHANGE, payload: { articleCategoryLoading: false } });
      reject(err);
    })
  })
};

/**
 * 保存当前tabs下标信息
 *
 */
export const setHomeTabsIndexAndScroll = (
  tabsIndexValue: number,
  tabsScroll: any = {},
) => ({
  type: actionTypes.HOME_TABS_INDEX_AND_SCROLL,
  payload: {
    tabsIndexValue,
    tabsScroll,
  },
});