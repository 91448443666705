import axios from '../axios';

export const getArticle = ({
  page,
  size,
  id,
}: API.GetArticlePropType): Promise<any> => {
  return axios.request({
    method: 'get',
    url: '/api/article',
    params: {
      categoryId: id,
      page: page,
      pageSize: size,
      sort: 'DESC',
      sortField: 'topStatus,createdDate',
    }
  })
}

export const getArticleId = (id: number): Promise<any> => {
  return axios.request({
    method: 'get',
    url: `/api/article/${id}`,
  })
}

export const getLikeId = (id: number): Promise<any> => {
  return axios.request({
    method: 'put',
    url: `/api/article/like/${id}`,
  })
}
export const getCommentarticid = (articleId: any): Promise<any> => {
  return axios.request({
    method: 'get',
    url: `/api/article/comment/${articleId}`,
  })
}
interface AccountComment {
  anonymous: boolean;
  articleId: number;
  content: string;
}

export const getComment = ({anonymous,articleId,content}:AccountComment): Promise<any> => {
  return axios.request({
    method: 'post',
    url: '/api/article/comment',
    data:{
      anonymous,
      articleId,
      content,
    }
  })
}
export const getCommentlikeId = (id: number): Promise<any> => {
  return axios.request({
    method: 'put',
    url: `/api/article/comment/like/${id}`,
  })
}

export const delComment = (id: number): Promise<any> => {
  return axios.request({
    method: 'delete',
    url: `/api/article/comment/${id}`,
  })
}