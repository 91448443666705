import people from './people.png';
import favorite from './favorite.png';
import business from './business.png';
import accountBalance from './account-balance.png';
import accessibility from './accessibility.png';
import peopleActive from './people-active.png';
import favoriteActive from './favorite-active.png';
import accountBalanceActive from './account-balance-active.png';
import accessibilityActive from './accessibility-active.png';
import top from './top.png';

const iconList: any = {
  people,
  favorite,
  business,
  accountBalance,
  accessibility,
  top,
  peopleActive,
  favoriteActive,
  accountBalanceActive,
  accessibilityActive,
}

export default iconList;