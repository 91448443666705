const actionTypes: { [key: string]: string } = {
  SET_USER_INFO: 'SET_USER_INFO',
  SET_ROUTE: 'SET_ROUTE',
  SET_SCROLL_HISTORY: 'SET_SCROLL_HISTORY',
  SET_USER_STATUS: 'SET_USER_STATUS',
  SET_DYNAMIC_SOURCEDATA: 'SET_DYNAMIC_SOURCEDATA',
  SET_ARTICLE: 'SET_ARTICLE',
  SET_ARTICLE_LOADING: 'SET_ARTICLE_LOADING',
  CLEAR_USER_INFO: 'CLEAR_USER_INFO',
  HOME_CHANGE: 'HOME_CHANGE',
  HOME_ARTICLE_LIST: 'HOME_ARTICLE_LIST',
  HOME_TABS_INDEX_AND_SCROLL: 'HOME_TABS_INDEX_AND_SCROLL',
  SET_WX_CONFIG: 'SET_WX_CONFIG',
  HOME_ARTICLE_LIST_CHANGE: 'HOME_ARTICLE_LIST_CHANGE',
}

export default actionTypes;