import { combineReducers } from 'redux'

import user from './user'
import route from './route'
import home from './home'
import article from './article'

export default combineReducers({
  user,
  route,
  home,
  article,
})