import actionTypes from '../actions';
import { ReducerPropsType } from '../store.d';

export interface RouteStateType {
  route: any;
  scrollHistory: { [key: string]: number };
}

const routeState: RouteStateType = {
  route: {},
  scrollHistory: {}
};

const reducer = (state = routeState, { type, payload }: ReducerPropsType) => {
  switch (type) {
    case actionTypes.SET_ROUTE:
      return {
        ...state,
        route: payload.route
      }
    case actionTypes.SET_SCROLL_HISTORY:
      const s = { ...state };
      if (payload.name) {
        s.scrollHistory[(payload.name as string)] = payload.height
      }
      return s;
    default:
      return state
  }
}

export default reducer;