import { useCallback, useMemo } from 'react';
import { matchPath, useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import smoothscroll from 'smoothscroll-polyfill';
import styles from './index.module.css';
import { RouteType } from '../../../src/routes/router';
import iconList from './icon';

smoothscroll.polyfill();

interface NavPropsType {
  sourceData: RouteType[];
  style: React.CSSProperties;
}

const activeStyle: React.CSSProperties = {
  color: '#108ee9',
};

const Nav: React.FC<NavPropsType> = ({ sourceData, style }) => {
  const location = useLocation();

  const renderDom = useCallback((title: string, icon: string, path: string) => {

    const escapedPath =
      path && path.replace(/([.+*?=^!:${}()[\]|/\\])/g, "\\$1");
    
    const match = escapedPath
      ? matchPath(location.pathname, {
          path: escapedPath,
        })
      : null;

    const titleStyle = match ? activeStyle : {};

    const iconKey = match ? `${icon}Active` : icon;

    return (
      <div key={title}>
        <Link className="top" to={path}>
          <img src={iconList[iconKey]} alt="" />
        </Link>
        <span style={titleStyle}>{title}</span>
      </div>
    )
  }, [location]);

  const dom = useMemo(() => sourceData.map(({ title, icon, path, openLaunchWeapp }: RouteType) => {
    return openLaunchWeapp ? (
      <div key={title}>
        {/* @ts-ignore */}
        <wx-open-launch-weapp
          username={openLaunchWeapp}
          id="launch-btn"
          style={{ flex: 1, display: 'flex', width: '100%', height: '100%' }}
        >
          <script type="text/wxtag-template">
            <div style={{ display: 'inline-block', width: '100%', height: '100%' }}>
              <i
                style={{
                  margin: '0 auto',
                  width: '30px',
                  height: '30px',
                  borderRadius: '50%',
                  backgroundColor: '#fff',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  transition: 'all .3s',
                }}
              >
                <img src={iconList[icon as string]} alt="" style={{ display: 'inline-block', width: '16px' }} />
              </i>
              <div style={{ width: '100%', textAlign: 'center', marginTop: '4px', fontSize: '12px', lineHeight: 1.15 }}>{title}</div>
            </div>
          </script>
          {/* @ts-ignore */}
        </wx-open-launch-weapp>
      </div>
    ) : (
      renderDom((title as string), (icon as string), (path as string))
    )
  }), [sourceData, renderDom]
  );

  return (
    <nav className={styles.nav} style={style}>
      {dom}
    </nav >
  )
};

export default Nav;

