import actionTypes from './index';
import { ReducerPropsType } from '../store.d';

export const setRoute = (route: any): ReducerPropsType => ({
  type: actionTypes.SET_ROUTE,
  payload: {
    route
  }
});

export const setScrollHistory = (name: any, height: number): ReducerPropsType => ({
  type: actionTypes.SET_SCROLL_HISTORY,
  payload: {
    name,
    height,
  }
});