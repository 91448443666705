import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Switch, useHistory, useLocation } from 'react-router';
import { NavLink } from 'react-router-dom';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { createSelector } from 'reselect';
import { BackToTop, Carousel, SearchBar } from 'zarm';
import { setArticleCategory as updateArticleCategory } from '../redux/actions/home';
import { getBanner } from '../request/modules/home';
import router from '../routes';
import { RouteType } from '../routes/router';
import './square.module.css';

const articleCategoryListSelector = createSelector((state: any) => state?.home?.articleCategoryList, (articleCategoryList: any[]) => articleCategoryList?.length === 0 ? [] : articleCategoryList?.filter((item: any) => item?.position === 'SQUARE'));

const { REACT_APP_BASEIMGURL } = process.env;

const upStyle: React.CSSProperties = {
  width: 30,
  height: 30,
  lineHeight: '30px',
  textAlign: 'center',
  backgroundColor: '#fff',
  color: '#999',
  fontSize: 15,
  borderRadius: '50%',
  boxShadow: '0 2px 10px 0 rgba(0, 0, 0, 0.2)',
  cursor: 'pointer',
};

interface SquareLayoutPropsType {
  route: RouteType;
  setArticleCategory: (position: "HOME" | "SQUARE") => Promise<unknown>;
}

const containerStyle: React.CSSProperties = {
  position: 'relative',
};

const mainStyle: React.CSSProperties = {
  height: 'calc(100vh - 60px - env(safe-area-inset-bottom))',
  overflowY: 'scroll',
};

const carouselImageStyle: React.CSSProperties = {
  width: '100%',
  height: '180px',
};

const navStyle: React.CSSProperties = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '14px 0',
  background: '#fff',
};

const grayLine: React.CSSProperties = {
  borderLeft: '1px solid #B9B9B9',
  height: '11px',
};

const navItemStyle: React.CSSProperties = {
  flex: 1,
  textAlign: 'center',
  cursor: 'pointer',
  fontSize: '18px',
  color: '#333333',
};

const activeStyle: React.CSSProperties = {
  color: '#3385FF',
};

const SquareLayout = ({ route: { routes } }: SquareLayoutPropsType) => {
  const articleCategoryList = useSelector(articleCategoryListSelector);
  const dispatch: ThunkDispatch<any, any, AnyAction> = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const [banners, setBanners] = useState<any[]>([]);

  const scrollContainer = useCallback(() => document.querySelector('.square-main'), []);

  const setArticleCategory = useCallback((position) => dispatch(updateArticleCategory(position)), [dispatch]);

  const goSearch = useCallback(() => {
    history.push('/search');
  }, [history]);

  const carouselItemDoms = useMemo(() => banners.map((item: any, i) => (
    <div key={item.id} className="carousel__item__pic" onClick={() => {
      try {
        const url = new URL(item.link);
        if (url.host === window.location.host) {
          history.push(url.hash.substring(1));
          return;
        }
        window.location.href = item.link;
        return;
      } catch (e) { }
    }}>
      <img src={`${REACT_APP_BASEIMGURL}${item.image}`} alt="" draggable={false} style={carouselImageStyle} />
    </div>
  )), [history, banners]);

  const routeDoms = useMemo(() => router.renderRoute(routes as RouteType[]), [routes]);

  const navLinkDoms = useMemo(() => articleCategoryList?.map((item) => (
    <React.Fragment key={item.id!}>
      <NavLink exact to={`/main/discussion/${item.id!}`} style={navItemStyle} activeStyle={activeStyle}>{item.name!}</NavLink>
      <div style={grayLine} />
    </React.Fragment>
  )), [articleCategoryList]);

  useEffect(() => {
    getBanner('广场').then((response) => {
      if (response?.code === 0 && response?.data?.length) {
        setBanners(response?.data);
      }
    });
  }, []);

  useEffect(() => {
    setArticleCategory('SQUARE');
  }, [setArticleCategory]);

  useEffect(() => {
    if (articleCategoryList?.length && (location.pathname === '/main/discussion' || location.pathname === '/main/discussion/')) {
      history.replace(`/main/discussion/${articleCategoryList?.[0].id!}`)
    }
  }, [articleCategoryList, history, location]);

  return (
    <div style={containerStyle}>
      <div className="square-main" style={mainStyle}>
        <SearchBar shape="round" onFocus={goSearch} />
        <Carousel>
          {carouselItemDoms}
        </Carousel>
        <div style={navStyle}>
          {navLinkDoms}
          <NavLink to="/main/discussion/advice" style={navItemStyle} activeStyle={activeStyle}>建言建语</NavLink>
        </div>
        <Switch>
          {routeDoms}
        </Switch>
        <BackToTop scrollContainer={scrollContainer}>
          <div
            style={upStyle}
          >
            Up
          </div>
        </BackToTop>
      </div>
    </div>
  );
}

export default SquareLayout;
